// import Solarsystem from "./Solarsystem"
import Project from "./projects"
import Background from "./background"
import Education from "./education"
import Motivation from "./motivation"
import Contact from "./contact"
import Skills from "./Skills"

export default function LandingPage() {
    return (
        <>
        <div className="flex flex-col m-5">
            <h1 className="text-6xl text-center">
                Hi, I am Sina
            </h1>
            <h2 className="text-2xl text-gray-600 text-center">Web Design | Full-Stack Software Developer</h2>
            <div className="mt-5 md:flex grayscale-10 shadow border">
            <div className="lg:h-auto flex-none md:rounded-t-none md:rounded-l overflow-hidden flex justify-center items-center">
                    <img
            className="rounded-full h-60 w-60 m-5"
            src={require("./sinaklughardt.jpeg")}
            ></img>
                </div>
            <div className="flex flex-col text-left mt-5 pb-5 text-xl">
                <h1 className="text-2xl md:text-3xl p-3 text-center md:text-left">Welcome to the Sinaverse</h1>
                <p className="p-3">As a full-stack software developer I explore the wonders of code
                    and the vast reaches of space.
                    Join me on this epic journey as we push boundaries,
                    craft captivating user experiences, and uncover hidden treasures of innovation.
                    Together, let's boldly venture into the unknown, one line of code at a time. </p>
                <p className="p-3">See you among the stars!</p>
            </div>
            </div>

            {/* <Link
            to="/sinaverse"
            className="text-center m-20 mt-0 animate-pulse">
                <button className="font-mono text-3xl text-white bg-transparent hover:bg-violet-700 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-violet-700 hover:border-transparent">Enter the Sinaverse</button>
                </Link> */}
            </div>
            <div className="text-center lg:flex md:flex justify-center">
            <Project />
            <Education />
            <Skills />
            <Motivation />
            <Background />
            <Contact />
            </div>
        </>
    )
};
